/* Mobile Nav */
/* eslint-disable react/jsx-no-target-blank */
import { memo } from 'react';

import Link from '../ActiveLink';
import { useAccountState } from '../context/account-context';

import SearchIcon from './Search/SearchIcon';
import SC from './headerElements';

const handleSubnavLink = (e) => {
  if (window.innerWidth < 640) {
    e.preventDefault();
    e.stopPropagation();
    e.target.parentElement.classList.toggle('is-active');
  }
};

const UserProfileLink = ({ fname, lname, onClick }) => (
  <a role="button" href="/profile" onClick={onClick}>
    {fname} {lname}
  </a>
);

const LogoutLink = ({ onClick, className }) => (
  <SC.StyledLi className={className}>
    <a href="#/" onClick={onClick}>
      Logout
    </a>
  </SC.StyledLi>
);

const NavContent = memo(({ logout, fname, lname, isLoggedIn, searchOpen, toggleSearch }) => (
  <ul className="main-nav">
    <li>
      <a href="/">
        <img alt="NTUC Link" className="logo-desktop" src="/static/img/common/logo.svg" />
      </a>
    </li>

    <SC.Flex className="styled-flex">
      {isLoggedIn && (
        <li className="head-visible-mobile">
          <Link href="/profile">
            <a className="is-active" style={{ textAlign: 'center', fontSize: 18 }}>
              {fname} {lname}
            </a>
          </Link>
        </li>
      )}
      <li className="has-subnav">
        <a role="button" href="/all-partners" data-testid="sub-nav" onClick={handleSubnavLink}>
          Shop with Our Partners
        </a>
        <SC.SubNavMobile>
          <ul>
            <li>
              <a href="/all-partners">All Partners</a>
            </li>
            <li>
              <a href={`${process.env.NEXT_PUBLIC_WP_URL}/visit-downtown-east`}>Downtown East</a>
            </li>
          </ul>
        </SC.SubNavMobile>
      </li>
      <li>
        <Link href="/exchange">
          <a>Convert Linkpoints</a>
        </Link>
      </li>
      <li>
        <Link href="https://support.link.sg/hc/en-us">
          <a>FAQs</a>
        </Link>
      </li>

      {/* Login/Logout & User Menu */}
      {isLoggedIn ? (
        <>
          <li className="loggedin has-subnav">
            <UserProfileLink fname={fname} lname={lname} onClick={handleSubnavLink} />
            <SC.SubNavMobile>
              <ul>
                <li>
                  <a href="#/" onClick={logout}>
                    Logout
                  </a>
                </li>
              </ul>
            </SC.SubNavMobile>
          </li>
          <LogoutLink onClick={logout} className="head-visible-mobile" />
        </>
      ) : (
        <SC.StyledLi>
          <Link href="/login">
            <a>Login</a>
          </Link>
        </SC.StyledLi>
      )}
    </SC.Flex>
    <SC.Flex>
      <li className="head-hidden-mobile">
        <SearchIcon searchOpen={searchOpen} toggleSearch={toggleSearch} />
      </li>
    </SC.Flex>
  </ul>
));

const Nav = ({ navOpen, searchOpen, toggleSearch }) => {
  const { accountData, isLoggedIn, logout } = useAccountState();
  return (
    <SC.Nav className={navOpen ? 'is-open' : ''}>
      <NavContent
        isLoggedIn={isLoggedIn}
        fname={accountData?.fname}
        lname={accountData?.lname}
        toggleSearch={toggleSearch}
        searchOpen={searchOpen}
        logout={logout}
      />
    </SC.Nav>
  );
};

export default Nav;
